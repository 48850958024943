const breadcrumbs = {
  // INICIO
  '/admin': [
    {
      label: 'Início',
      actived: true,
    },
  ],

  // STORE
  '/admin/store': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      label: 'Minha Loja',
      actived: true,
    },
  ],
  '/admin/store/themes': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/store',
      label: 'Minha Loja',
    },
    {
      label: 'Temas',
      actived: true,
    },
  ],
  '/admin/store/menus': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/store',
      label: 'Minha Loja',
    },
    {
      label: 'Navegação',
      actived: true,
    },
  ],
  '/admin/store/menus/new': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/store',
      label: 'Minha Loja',
    },
    {
      path: '/admin/store/menus',
      label: 'Navegação',
    },
    {
      label: 'Novo menu',
      actived: true,
    },
  ],
  '/admin/store/checkout': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/store',
      label: 'Minha Loja',
    },
    {
      label: 'Checkout',
      actived: true,
    },
  ],
  '/admin/store/pages': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/store',
      label: 'Minha Loja',
    },
    {
      label: 'Páginas',
      actived: true,
    },
  ],
  '/admin/store/pages/new': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/store',
      label: 'Minha Loja',
    },
    {
      path: '/admin/store/pages',
      label: 'Páginas',
    },
    {
      label: 'Nova página',
      actived: true,
    },
  ],
  '/admin/store/scripts': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/store',
      label: 'Minha Loja',
    },
    {
      label: 'Scripts',
      actived: true,
    },
  ],

  // SIGNATURE
  '/admin/signature': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      label: 'Assinatura',
      actived: true,
    },
  ],

  // PRODUCTS
  '/admin/products': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      label: 'Produtos',
      actived: true,
    },
  ],
  '/admin/products/new': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/products',
      label: 'Produtos',
    },
    {
      label: 'Novo',
      actived: true,
    },
  ],
  '/admin/products/edit/:id': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/products',
      label: 'Produtos',
    },
    {
      label: 'Editar',
      actived: true,
    },
  ],
  '/admin/products/colors': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/products',
      label: 'Produtos',
    },
    {
      label: 'Cores',
      actived: true,
    },
  ],
  '/admin/products/stocks': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/products',
      label: 'Produtos',
    },
    {
      label: 'Estoques',
      actived: true,
    },
  ],
  '/admin/products/attributes': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/products',
      label: 'Produtos',
    },
    {
      label: 'Atributos',
      actived: true,
    },
  ],
  '/admin/products/attributes/new': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/products',
      label: 'Produtos',
    },
    {
      path: '/admin/products/attributes',
      label: 'Atributos',
    },
    {
      label: 'Novo',
      actived: true,
    },
  ],
  '/admin/products/categories': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/products',
      label: 'Produtos',
    },
    {
      label: 'Categorias',
      actived: true,
    },
  ],
  '/admin/products/categories/new': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/products',
      label: 'Produtos',
    },
    {
      path: '/admin/products/categories',
      label: 'Categorias',
    },
    {
      label: 'Nova',
      actived: true,
    },
  ],
  '/admin/products/categories/edit/:id': [
    {
      path: '/admin/products',
      label: 'Início',
    },
    {
      path: '/admin/products',
      label: 'Produtos',
    },
    {
      path: '/admin/products/categories',
      label: 'Categorias',
    },
    {
      label: 'Editar',
      actived: true,
    },
  ],

  // CLIENTS
  '/admin/clients': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      label: 'Clientes',
      actived: true,
    },
  ],
  '/admin/clients/new': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/clients',
      label: 'Clientes',
    },
    {
      label: 'Novo',
      actived: true,
    },
  ],
  '/admin/clients/details': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/clients',
      label: 'Clientes',
    },
    {
      label: 'Detalhes',
      actived: true,
    },
  ],
  '/admin/clients/edit': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/clients',
      label: 'Clientes',
    },
    {
      label: 'Editar',
      actived: true,
    },
  ],

  // PEDIDOS
  '/admin/orders': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      label: 'Pedidos',
      actived: true,
    },
  ],
  '/admin/orders/checkouts': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/orders',
      label: 'Pedidos',
    },
    {
      label: 'Abandonados',
      actived: true,
    },
  ],
  '/admin/orders/checkouts/details': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/orders',
      label: 'Pedidos',
    },
    {
      path: '/admin/orders/checkouts',
      label: 'Abandonados',
    },
    {
      label: 'Detalhes',
      actived: true,
    },
  ],
  '/admin/orders/drafts': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/orders',
      label: 'Pedidos',
    },
    {
      label: 'Criar Pedidos',
      actived: true,
    },
  ],
  '/admin/orders/details': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/orders',
      label: 'Pedidos',
    },
    {
      label: 'Detalhes',
      actived: true,
    },
  ],

  // RELATORIOS
  '/admin/reports': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      label: 'Relatórios',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-day': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por dia',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-hour': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por hora',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-state': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por estado',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-payment': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por método de pagamento',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-payment-status': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por status de pagamento',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-parcel': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por parcela',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-carrier': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por formas de envio',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-device': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por dispositivo',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-customer': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Melhores clientes',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-gender': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por gênero',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-age-range': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por faixa etária',
      actived: true,
    },
  ],
  '/admin/reports/register-customer': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Clientes cadastrados',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-coupon': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por cupom',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-free-shipping': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas com frete grátis',
      actived: true,
    },
  ],
  '/admin/reports/register-mailing': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Cadastro em newsletter',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-campaign': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por campanha',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-product': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por produto',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-variation': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por variação',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-brand': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por marca',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-category': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por categoria',
      actived: true,
    },
  ],
  '/admin/reports/sale-by-attribute': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/reports',
      label: 'Relatórios',
    },
    {
      label: 'Vendas por atributo',
      actived: true,
    },
  ],

  // CONFIGURAÇÕES
  '/admin/settings': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      label: 'Configurações',
      actived: true,
    },
  ],
  '/admin/settings/general': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/settings',
      label: 'Configurações',
    },
    {
      label: 'Gerais',
      actived: true,
    },
  ],
  '/admin/settings/payments': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/settings',
      label: 'Configurações',
    },
    {
      label: 'Formas de pagamento',
      actived: true,
    },
  ],
  '/admin/settings/payments/new': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/settings',
      label: 'Configurações',
    },
    {
      path: '/admin/settings/payments',
      label: 'Formas de pagamentos',
    },
    {
      label: 'Nova forma de pagamento',
      actived: true,
    },
  ],
  '/admin/settings/freight': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/settings',
      label: 'Configurações',
    },
    {
      label: 'Frete e entrega',
      actived: true,
    },
  ],
  '/admin/settings/freight/new': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/settings',
      label: 'Configurações',
    },
    {
      path: '/admin/settings/freight',
      label: 'Frete e entrega',
    },
    {
      label: 'Mais entregas',
      actived: true,
    },
  ],
  '/admin/settings/notifications': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/settings',
      label: 'Configurações',
    },
    {
      label: 'Notificações',
      actived: true,
    },
  ],
  '/admin/settings/users': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/settings',
      label: 'Configurações',
    },
    {
      label: 'Usuários',
      actived: true,
    },
  ],
  '/admin/settings/users/new': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/settings',
      label: 'Configurações',
    },
    {
      path: '/admin/settings/users',
      label: 'Usuários',
    },
    {
      label: 'Novo usuário',
      actived: true,
    },
  ],
  '/admin/settings/files': [
    {
      path: '/admin',
      label: 'Início',
    },
    {
      path: '/admin/settings',
      label: 'Configurações',
    },
    {
      label: 'Arquivos',
      actived: true,
    },
  ],
};

export default breadcrumbs;
